
import { defineComponent, onMounted } from 'vue'
import { routGo } from '@/hooks/links'
import SecondFooter from '@/components/SecondFooter.vue'

export default defineComponent({
  name: 'CustomerStories',
  components: {
    SecondFooter
  },
  setup () {
    onMounted(() => {
      _hmt.push(['_trackPageview', '/customerstories'])
    })
    return { routGo }
  }
})
